import Home from './components/Home.vue';
import What from './components/what/What.vue';
import Farmer from './components/who/Who.vue';
import Contact from './components/contact/Contact.vue';

export const routes = [
    { path: '/', component: Home },
    { path: '/what', component: What },
    { path: '/who', component: Farmer },
    { path: '/contact', component: Contact }
];