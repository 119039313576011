<template>
    <div>
        <div class="jumbotron jumbotron-fluid">
            <div class="container animate__animated animate__fadeIn">
                <h2 class="display-4">Who I Am</h2>
            </div>
        </div>

        <div class="container animate__animated animate__fadeIn">

            <div class="secondary-container">  
                <div class="row">
                    <div class="col-sm-12 card-row">
                        <h3 class="section-title-lighter text-center">I'm an Entrepreneur</h3>
                    <p class="title-text">I am constantly working to blaze my own trail. From business ventures, to trying out personal projects, my entrepreneurial drive is strong!</p>
                    </div>
                </div>
            </div>

            <div class="secondary-container">  
                <div class="row">
                    <div class="col-sm-12 card-row">
                        <h3 class="section-title-lighter">I'm a Homesteader</h3>
                        <p class="title-text">Sustainable living has always been a goal of mine. My husband and I are working on our own solar powered homestead! My ultimate goal is to grow produce and provide it to families in need! </p>
                    </div>
                </div>
            </div>

            <div class="secondary-container">  
                <div class="row">
                    <div class="col-sm-12 card-row">
                        <h3 class="section-title-lighter">I'm a Creator</h3>
                        <p class="title-text">I love creating things! From creating tutorials to teach people games for Board Simple, to writing stories for Readabear to making physical things. I love creating!</p>
                    </div>
                </div>
            </div>

            <div class="secondary-container">  
                <div class="row">
                    <div class="col-sm-12 card-row">
                        <h3 class="section-title-lighter">I'm an Adventurer</h3>
                        <p class="title-text">I have always enjoyed the adventure of life! From backpacking on a budget through western Europe, to studying abroad in Australia, to building trails in the western United States, to trying van life for 2 years. I love the adventure of life!</p>
                    </div>
                </div>
            </div>

            <div class="secondary-container">  
                <div class="row">
                    <div class="col-sm-12 card-row">
                        <h3 class="section-title-lighter">I'm a Philanthropist</h3>
                        <p class="title-text">I have completed two terms of national service through AmeriCorps. I was a volunteer wildlife rehabilitator for several years. I have helped make meals for Meals on Wheels. Helping and serving have always been important to me.</p>
                    </div>
                </div>
            </div>

            <div class="secondary-container">  
                <div class="row">
                    <div class="col-sm-12 card-row">
                        <h3 class="section-title-lighter">I'm an Environmentalist</h3>
                        <p class="title-text">Through all of my ventures, I always try my best to keep the impact on the environment in mind. It's not always an easy task in today's world of conveniences. Whether or not you truly believe that humans are impacting the environment, does it really hurt to use a little less to make the next generation's stay here better?</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    data() {
        return {
            farmArticles: [{
                title: 'Article 1',
                image: '/img/article1.jpg',
                alt: 'this image isn\'t showing',
                description: 'The first article.',
                farmLink: ''
            },
           
            ]
        }
    },
    methods: {

    }
}
</script>

<style>
    .who-descriptions {
        width: 800px;
        text-align: center;
        margin: 0 auto;
    }
</style>