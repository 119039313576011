<template>
  <div id="app">

    <Header></Header>

    <router-view class="mb"></router-view> 

    <Footer></Footer>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    Header, 
    Footer
   // Home
  },
  methods: {

  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

a:visited, a:link, a:active {
    text-decoration: none;
    transition: .3s;
    color: black;
  }

  a:hover {
    text-decoration: none;
    transition: .3s;
  }
.jumbotron h2 {
  font-family: 'Sigmar One', cursive;
  padding-top: 40px;
}

.card-img-top {
    margin: 10px;
    width: 80%;
    box-shadow: 0px 0px 5px #aaa;
    border-radius: 20px;
}


.section-title {
    margin-bottom: 50px;
    margin-top: 150px;
    font-weight: bold;
    color:  #333;
    text-shadow: 0px 1px 0px #ddd;
   /* -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #EB6331;*/
}

.section-title-lighter {
  color: #777;
  margin-top: 50px;
  text-shadow: 0px 1px 0px #ddd;
  margin-bottom: 30px;
  font-family: 'Sigmar One', cursive;
}

.section-title-lighter:after {
  background: none repeat scroll 0 0 #EB6331;
  bottom: -10px;
  content: "";
  display: block;
  height: 3px;
  position: relative;
  width: 100px;
  margin-left: auto;
  margin-right: auto;
}

.title-text {
  margin-bottom: 50px;
}

.jumbotron {
  border-bottom-right-radius: 50px;
  border-bottom-left-radius: 50px;
  border-bottom: solid 1px #ddd;
  background: #EB6331;
  color: #eee;
  margin-top: 60px;
  margin-bottom: 100px;
}

.lead {
  color: #eee !important;
}

.secondary-container {
  background: #eee;
  border-radius: 50px;
  padding: 20px;
  margin-bottom: 50px;
}

.second-secondary-margin {
  margin-top: 150px;
}

.pages-margin {
  margin-top: 100px;
  margin-bottom: 80px;
}
.home-h3 {
  color: #fff; 
  font-family: 'Lato', sans-serif; 
  font-size: 30px; 
  margin-bottom: -20px; 
  margin-left: 50px; 
  text-align: left;
  font-weight: bold;
}

.home-h1 {
  font-family: 'Sigmar One', sans-serif; 
  font-size: 80px; 
  text-align: center;
}
.home-h3-bottom {
    color: #fff; 
    font-weight: bold; 
    font-family: 'Lato', sans-serif; 
    text-align: right; 
    margin-top: -25px;
  }

[v-cloak] {
  display: none !important; 
}

@media only screen and (max-width: 991px) {
  .jumbotron {
    margin-top: 56px;
  }
  .nav-slash {
    display: none;
  }
  .home-h1 {
    font-size: 60px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .card-img-top {
    margin-top: 40px;
  }
  .card-text:last-child {
    margin-bottom: 40px;
  }
  .display-4 {
    font-size: 1.8rem;
  }
  .home-h1 {
    font-size: 20px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .home-h3 {
    font-size: 12px;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  .home-h3-bottom {
    color: #fff; 
    font-size: 12px;
    margin-top: 0px;
    text-align: center;
    margin-bottom: -50px;
  }
  .home-button h2 {
    font-size: 16px;
  }
  .hidden-sm {
    display: none;
  }
  .h3, h3 {
    font-size: 1.3rem;
  }
}

</style>
