import Vue from 'vue'
import VueRouter from 'vue-router'; // Import Vue Router

import App from './App.vue'
import { routes } from './routes';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faExternalLinkAlt)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(VueRouter) // Need to run to use Vue Router

const router = new VueRouter({
  mode: 'history',
  routes
})

new Vue({
  el: '#app',
  router,
  render: h => h(App),
}).$mount('#app')
