<template>
    <div>
        <div class="jumbotron jumbotron-fluid">
            <div class="container animate__animated animate__fadeIn">
                <h2 class="display-4">Contact Me</h2>
            </div>
        </div>

       <div class="container animate__animated animate__fadeIn">
            <h3 class="section-title-lighter">Let's Talk!</h3>
            <p class="title-text">Have a project you would like to talk to me about? Want to just talk? <br>Leave me a message and I'll be in touch.</p>

            <div class="secondary-container">
                <div class="row">
                    <form class="col-md-8 offset-md-2" method="POST" action="https://formspree.io/f/mzbkrowv">
                        <input type="text" id="fname" name="firstname" placeholder="Your name">
                        <input type="text" id="email" name="email" placeholder="Your email address">
                        <textarea id="subject" name="subject" placeholder="Your message" style="height:200px"></textarea>
                        <input type="submit" value="Submit">
                    </form>
                </div>
            </div>
            <!--<div class="secondary-container">
                <div class="row">
                    <div class="offset-lg-3 col-lg-6 col-sm-12 card-row">
                    <p class="title-text"></p>
                    <form>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label for="inputEmail4">Name</label>
                                <input type="email" class="form-control" id="inputEmail4">
                            </div>
                            <div class="form-group col-md-6">
                                <label for="inputPassword4">Email</label>
                                <input type="password" class="form-control" id="inputPassword4">
                            </div>

                            <div style="margin-top: 50px" class="form-group col-md-12">
                                <label for="exampleFormControlTextarea1">Message</label>
                                <textarea class="form-control" id="exampleFormControlTextarea1" rows="12"></textarea>
                            </div>
                        </div>
        
                        <div class="form-group">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="gridCheck">
                                <label class="form-check-label" for="gridCheck">
                                Check me out
                                </label>
                            </div>
                        </div>
                        <button type="submit" class="btn btn-primary">Sign in</button>
                    </form>
                    </div>
                </div>
            </div>-->
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            farmArticles: [{
                title: 'Article 1',
                image: '/img/article1.jpg',
                alt: 'this image isn\'t showing',
                description: 'The first article.',
                farmLink: ''
            },
           
            ]
        }
    },
    methods: {

    }
}
</script>

<style>
input[type=text], select, textarea {
  width: 100%; /* Full width */
  padding: 12px; /* Some padding */ 
  border: 1px solid #ccc; /* Gray border */
  border-radius: 4px; /* Rounded borders */
  box-sizing: border-box; /* Make sure that padding and width stays in place */
  margin-top: 6px; /* Add a top margin */
  margin-bottom: 16px; /* Bottom margin */
  resize: vertical /* Allow the user to vertically resize the textarea (not horizontally) */
}

/* Style the submit button with a specific background color etc */
input[type=submit] {
  background-color: #17c9ff;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: ease .3s;
}

/* When moving the mouse over the submit button, add a darker green color */
input[type=submit]:hover {
  background-color: #5cd9ff;
  transition: ease .3s;
}
</style>