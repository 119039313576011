<template>
    <div class="home-background">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 hi-im-title">
                

                        <h3 class ="home-h3 animate__animated animate__fadeIn"
                        >Hi! I'm </h3>
                    
                        <h1 class="home-h1 animate__animated animate__fadeIn">
                            <span class="white-letter">B</span>
                            <span class="orange-letter">e</span>
                            <span class="white-letter">c</span>
                            <span class="white-letter">k</span>
                            <span class="white-letter">y</span>

                            <span class="white-letter">W</span>
                            <span class="orange-letter">ei</span>
                            <span class="white-letter">r</span>
                            <span class="orange-letter">au</span>
                            <span class="white-letter">ch</span>
                        </h1>
                    
                        <h3 class="home-h3-bottom animate__animated animate__fadeIn">(It's pronounced Why-Rock)</h3>
                </div>
            </div>
            
            <div v-if="show" name="fade2" class="row home-button-box">
                    <router-link  v-cloak
                        to="/what" 
                        tag="div" 
                        class="offset-md-1 col-md-5 home-button animate__animated animate__fadeInLeft animate__delay-1s">
                            <h2>What I <br class=
                            "hidden-sm">Do</h2>
                    </router-link>
                    <router-link v-cloak
                        to="/who"
                        tag="div" 
                        class="middle-button offset-md-1 col-md-5 home-button animate__animated animate__fadeInRight animate__delay-2s">
                            <h2>Who I <br class=
                            "hidden-sm">
                            Am</h2>
                    </router-link>
            </div>
            <div class="row bottom-button">
                    <router-link v-cloak
                        to="/contact" 
                        tag="div"
                        class="offset-md-1 col-md-5 home-button animate__animated animate__fadeInUp animate__delay-3s">
                            <h2> Contact<br class=
                            "hidden-sm">
                                Me</h2>
                    </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
      return {
          show: true
      }
  },
  mount() {
      this.onMountTransitionTitle();
  },
  methods: {
      onMountTransitionTitle() {
          return this.shoxw === true;
      }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped> 

    body {
        color: green;
    }
    .home-background {
        background: #EB6331;
        height: calc(100vh - 30px);
        height: -o-calc(100vh + 30px); /* opera */
        height: -webkit-calc(100vh + 30px); /* google, safari */
        height: -moz-calc(100vh + 30px); /* firefox */;
        }

    h1 {
        color: #fff;
    }
    .hi-im-title {
        margin-top: 150px;
    }

    .white-letter {
        -webkit-text-fill-color: white; /* Will override color (regardless of order) */
        -webkit-text-stroke-width: 1.5px;
        -webkit-text-stroke-color: orange;
    }
    .orange-letter {
        -webkit-text-fill-color: orange; /* Will override color (regardless of order) */

    }

    .home-button-box {
        margin-top: 100px;
    }

    a:active, a:visited, a:hover {
        text-decoration: none;
        color: white;
    }

    .home-button {
        display:inline-block; 
        text-align: center; 
        color: white;
        background: #EB6331; 
        margin: 0 auto;
        border-radius: 50px;
        border: solid 2px #f1916e;
        transition: 0.5s;
        cursor: pointer;
    }

    .home-button:hover {
        background: orange;
        border: solid 2px orange;
        color: #eee;
    }

    .home-button h2 {
        font-family: 'Sigmar One', cursive; 
        /*-webkit-text-fill-color: white; /* Will override color (regardless of order) */
        /*-webkit-text-stroke-width: 1px;*/
        /*-webkit-text-stroke-color: orange;*/
    }
    .card-body {
        text-align: left;
    }
    .bottom-button {
        margin-top: 50px;
    }
    [v-cloak] {
        display: none !important; 
    }
     @media only screen and (max-width: 767px) {
        .middle-button {
            margin-top: 20px;
        }
        .bottom-button {
            margin-top: 20px;
        }
        .hi-im-title {
            margin-top: 80px;
        }
    }

</style>
