<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top">
    <button 
      @click="toggleMe"
      class="navbar-toggler" 
      type="button" 
      data-toggle="collapse" 
      data-target="#navbarNavAltMarkup" 
      aria-controls="navbarNavAltMarkup" 
      aria-expanded="false" 
      aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
      <div class="navbar-nav">
        <!-- HOME LINK -->
        <router-link 
          to="/" 
          tag="li" 
          class="nav-item nav-link active animate__animated animate__fadeIn">
          <a>Home</a><span class="nav-slash"> /</span>
        </router-link>
        <!-- PROGRAMMER LINK -->
        <router-link 
          to="/what" 
          tag="li" 
          class="nav-item nav-link active animate__animated animate__fadeIn">
          <a>What I Do</a><span class="nav-slash"> /</span>
        </router-link>
        <!-- WHO LINK -->
        <router-link 
          to="/who" 
          tag="li" 
          class="nav-item nav-link active animate__animated animate__fadeIn">
          <a>Who I Am</a><span class="nav-slash"> /</span>
        </router-link>
        <!-- CONTACT LINK -->
        <router-link 
          to="/contact" 
          tag="li" 
          class="nav-item nav-link active animate__animated animate__fadeIn">
          <a>Contact</a>
        </router-link>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
    }
  },
  methods: {
    toggleMe() {
      console.log(true);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .nav-item a {
    margin-left: -10px;
    font-size: 20px;
    color: #f1916e;
  }

  .nav-item a:hover, .nav-link:hover {
    color: #eee;
  }

  .navbar-light {
    background: #eee;
    width: 100%;
  }

  .nav-slash {
    color: #f1916e;
    margin-left: 10px;
    margin-right: 10px;
  }

  .color {
    background:#EB6331;
    border-bottom-right-radius: 50px;
  }

  .bg-light {
    background: #EB6331 !important;
    box-shadow: 0px 0px 3px #EB6331;
  }

  .navbar-toggler {
    color: white !important;
  }
</style>
