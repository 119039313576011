<template>
    <div>
        <div class="jumbotron jumbotron-fluid">
            <div class="container animate__animated animate__fadeIn">
                <h2 class="display-4">What I Do</h2>
                <!--<p class="lead">I enjoy building my skills by making apps and websites that involve my interests. Check out the apps and websites I have made so far!</p>-->
            </div>
        </div>
        
        <div class="container animate__animated animate__fadeIn">

            <h3 class="section-title-lighter second-secondary-margin">My Websites</h3>
            <p class="title-text">Some of my personal and professional websites.</p>

            <div class="secondary-container">  
                <div class="row">
                    <div class="col-lg-6 col-sm-12 card-row" 
                        v-for="websiteApplication in websiteApplications" 
                        :key="websiteApplication"
                         style="margin-bottom: 30px;">
                        <!-- Application Image -->
                        <a :href="websiteApplication.websiteLink"><img :src="websiteApplication.image" class="card-img-top" :alt="websiteApplication.alt" style="border: solid white 10px"></a>  
                        <!-- Try It Button --
                        <button class="btn btn-warning" style="width: 80%; margin-top: 10px; margin-bottom: 10px;"><a :href="websiteApplication.websiteLink">Try it out!</a></button>-->
                        <!-- Title and Description -->
                        <div class="card-body" style="margin-left: 20px; margin-right: 20px">
                            <h5 class="card-title">{{ websiteApplication.title }}</h5>
                            <p class="card-text">{{ websiteApplication.description }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br><br>
                    <!--
            <h3 class="section-title-lighter">Applications I've Created</h3>
            <p class="title-text">I like to make applications that involve my other interests. here are a few I have made.</p>

            <div class="secondary-container">
                <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-12 card-row" v-for="programmingApplication in programmingApplications" :key="programmingApplication">
                        <img :src="programmingApplication.image" class="card-img-top" :alt="programmingApplication.alt">  
                        <button class="btn btn-warning" style="width: 80%; margin-top: 10px; margin-bottom: 10px"><a :href="programmingApplication.programLink">Try it out!</a></button>
                        <div class="card-body">
                            <h5 class="card-title">{{ programmingApplication.title }}</h5>
                            <p class="card-text">{{ programmingApplication.description }}</p>
                        </div>
                    </div>
                </div>
            </div>
            -->
    </div>
    
    
</template>

<script>
export default {
    data() {
        return {
            // PROGRAMMING SECTION
            programmingApplications: [{
                title: 'Days Until Average Last Frost',
                image: '/img/frost-calc.jpg',
                alt: 'this image isn\'t showing',
                description: 'The average last frost date for your area is very important for a gardener. This is a simple calculator to determine the days until the last frost.',
                programmingLink: ''
            },{
                title: 'Plant Friends and Foes',
                image: '/img/companion.jpg',
                alt: 'this image isn\'t showing',
                description: 'There are plants that are believed to help and others are believed to hinder the growth of other plants. This app helps find the friends and foes of garden plants.',
                programmingLink: ''
            }],

            // WEBSITE SECTION
            websiteApplications: [{
                title: 'Readabear',
                image: '/img/readabear.jpg',
                alt: 'this image isn\'t showing',
                description: 'Readabear is a website containing free children\'s books that I create.',
                websiteLink: 'https://readabear.com/'
            },{
                title: 'Board Simple',
                image: '/img/board-simple-website.jpg',
                alt: 'this image isn\'t showing',
                description: 'Board Simple contains tutorials that teach people how to play games.',
                websiteLink: 'https://boardsimplegames.com/'
            },{
                title: 'Vanlife Development',
                image: '/img/vanlife.jpg',
                alt: 'this image isn\'t showing',
                description: 'This is the website I created for the business I run with my husband.',
                websiteLink: 'https://vanlifedevs.com/'
            }
            ]
        }
    },
    methods: {

    }
}
</script>