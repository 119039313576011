<template>
    <div class="footer">Designed and Built by Rebecca Weirauch</div>
</template>

<script>
export default {
    methods: {
        
    }
}
</script>

<style>
    .footer {
        height: 30px;
        color: #bbb;
    }
</style>